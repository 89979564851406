<template>
	<div>
		<Attendance v-show="_attendance.length" :attendance="_attendance" />
		<table class="table table-compact table-hover" style="text-align: center; margin: 0">
			<thead>
				<tr>
					<th style="text-align: justify" class="col col-sm-3">Timesheet Name</th>
					<th style="text-align: justify" class="col col-sm-1">Project Code</th>
					<th style="text-align: justify" class="col col-sm-2">Project Name</th>
					<th style="text-align: justify" class="col col-sm-2">Approver</th>
					<th style="text-align: justify" class="col col-sm-2">Approval Status</th>
					<th style="text-align: justify" class="col col-sm-2">Total Hours</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="values in summary" :key="values.name">
					<td style="text-align: left">
						<a v-if="values.link" :href="values.link">{{ values.parent }}</a
						><span v-else>{{ values.parent }}</span>
					</td>
					<td style="text-align: left">{{ values.project }}</td>
					<td style="text-align: left">{{ values.project_name }}</td>
					<td style="text-align: left">{{ values.approver }}</td>
					<td style="text-align: left">{{ values.approval_status }}</td>
					<td style="text-align: center">{{ values.hours }}</td>
				</tr>
			</tbody>
		</table>
		<GitHistory v-show="_git_history.length" :git_history="_git_history" />
		<ApprovalReasons :approval_reasons="_approval_reasons" />
	</div>
</template>
<script>
import ApprovalReasons from './ApprovalReasons.vue'
import Attendance from './Attendance.vue'
import GitHistory from './GitHistory.vue'

export default {
	name: 'Timesheet',
	components: { ApprovalReasons, Attendance, GitHistory },
	props: ['frm', 'projects', 'attendance', 'approval_reasons', 'git_history'],
	data() {
		return {
			summary: [],
			_attendance: [],
			_approval_reasons: [],
			_git_history: [],
		}
	},
	mounted() {
		this.loadProjectSummary()
	},
	methods: {
		loadProjectSummary() {
			if (this.projects.length > 1) {
				this.summary = this.projects
			} else {
				frappe
					.xcall('time_and_expense.overrides.timesheet.get_timesheet_supplemental_data', { doc: this.frm.doc })
					.then(r => {
						this.summary = r.timesheets
						this._attendance = r.attendance
						this._git_history = r.git_history
						cur_frm.project_hours = r.timesheets
					})
			}
		},
	},
}
</script>
