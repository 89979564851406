import Timesheet from './Timesheet.vue'
import { createApp } from 'vue'
frappe.provide('time_and_expense')

time_and_expense.mount_table = (frm, projects) => {
	if (!frm?.$timesheet) {
		$(frm.fields_dict['project_totals'].wrapper).html($("<div id='project_totals'></div>").get(0))
		const app = createApp(Timesheet, {
			frm: frm,
			projects: projects || frm.projects || [],
			attendance: frm.attendance || [],
			approval_reasons: frm.approval_reasons || '',
			git_history: frm.git_history || [],
		})
		const target = $('#project_totals').get(0)
		if (!target) return
		frm.$timesheet = app.mount(target)
	}
	return frm.$timesheet
}
