<template>
	<table class="table table-compact table-hover">
		<tr>
			<th class="col-xs-2">Project</th>
			<th class="col-xs-2">Date and Time</th>
			<th class="col-xs-9">Message</th>
		</tr>
		<tr v-for="(row, row_index) in git_history" :key="row_index">
			<td>{{ row.project }}</td>
			<td>{{ row.commit_date }}</td>
			<td>
				<a :href="`/app/git-history/${row.name}`">{{ row.commit_message }}</a>
			</td>
		</tr>
	</table>
</template>
<script>
export default {
	name: 'GitHistory',
	props: ['git_history'],
}
</script>
<style scoped>
td,
th {
	text-align: left;
}
</style>
