<template>
	<p class="approval-reasons" v-html="approval_reasons"></p>
</template>
<script>
export default {
	name: 'ApprovalReasons',
	components: {},
	props: ['approval_reasons'],
}
</script>
