<template>
	<table class="table table-compact table-hover">
		<tr>
			<th class="col-xs-3">Checkin</th>
			<th class="col-xs-1">Type</th>
			<th class="col-xs-3">Date and Time</th>
			<th class="col-xs-5">Device</th>
		</tr>
		<tr v-for="(row, row_index) in attendance">
			<td>
				<a :href="`/app/employee-checkin/${row.name}`">{{ row.name }}</a>
			</td>
			<td>{{ row.log_type }}</td>
			<td>{{ row.time }}</td>
			<td>{{ row.device_id || '' }}</td>
		</tr>
	</table>
</template>
<script>
export default {
	name: 'Attendance',
	props: ['attendance'],
}
</script>
<style scoped>
td,
th {
	text-align: left;
}
</style>
