frappe.provide('time_and_expense')
frappe.provide('frappe.app')
frappe.provide('frappe.ui.form')
frappe.provide('frappe.query_report')

// rejection reason dialog
time_and_expense.rejection_reason_dialog = function (frm) {
	return new Promise(resolve => {
		let dialog = new frappe.ui.Dialog({
			title: __('Please provide a reason for rejection'),
			fields: [
				{
					fieldtype: 'HTML',
					label: __('Rejection Reason'),
					fieldname: 'rejection_reason',
				},
			],
			primary_action: function () {
				let reason = dialog.comment_box.value
				if (!reason) {
					frappe.throw('A rejection reason is required')
				}
				resolve({
					doc: frm.doc,
					docname: frm.doc.name,
					rejection_reason: reason,
					user: frappe.session.user,
				})
			},
			primary_action_label: __('Add Comment'),
		})
		let wrapper = $(dialog.fields_dict.rejection_reason.$wrapper)
		dialog.comment_box = frappe.ui.form.make_control({
			parent: wrapper,
			render_input: true,
			only_input: true,
			enable_mentions: true,
			reqd: 1,
			df: {
				fieldtype: 'Comment',
				fieldname: 'rejection_reason',
				reqd: 1,
			},
		})
		$('#freeze').css('z-index', 1021)
		wrapper.find('.btn-comment').hide()
		$(wrapper.find('.text-muted')[0]).hide()
		$(wrapper.find('.text-muted')[1]).hide()
		$(wrapper.find('.comment-input-header')[0]).hide()
		$(wrapper.find('.ql-editor').css({ border: 'solid 1px var(--error-border)' }))
		dialog.show()
		dialog.get_close_btn()
	})
}

time_and_expense.set_rejection_reason = async function (frm) {
	let values = await time_and_expense.rejection_reason_dialog(frm)
	cur_dialog.hide()
	frappe
		.xcall('time_and_expense.workflows.provide_rejection_reason_and_send_mail', {
			doctype: frm.doc.doctype,
			docname: frm.doc.name,
			rejection_reason: values.rejection_reason,
			user: frappe.session.user,
			employee_email: frm.doc.employee_email,
		})
		.then(r => {
			frm.reload_doc()
		})
}

time_and_expense.change_reason_dialog = function (frm) {
	return new Promise(resolve => {
		let dialog = new frappe.ui.Dialog({
			title: __('Please provide a reason for the project change'),
			fields: [
				{
					fieldtype: 'HTML',
					label: __('Project change Reason'),
					fieldname: 'change_reason',
				},
			],
			primary_action: function () {
				let reason = dialog.comment_box.value
				if (!reason) {
					frappe.throw('A project change reason is required')
				}
				resolve({
					doc: frm.doc,
					docname: frm.doc.name,
					change_reason: reason,
					user: frappe.session.user,
				})
			},
			primary_action_label: __('Add Comment'),
		})
		let wrapper = $(dialog.fields_dict.change_reason.$wrapper)
		dialog.comment_box = frappe.ui.form.make_control({
			parent: wrapper,
			render_input: true,
			only_input: true,
			enable_mentions: true,
			reqd: 1,
			df: {
				fieldtype: 'Comment',
				fieldname: 'change_reason',
				reqd: 1,
			},
		})
		wrapper.find('.btn-comment').hide()
		$(wrapper.find('.text-muted')[0]).hide()
		$(wrapper.find('.text-muted')[1]).hide()
		$(wrapper.find('.comment-input-header')[0]).hide()
		$(wrapper.find('.ql-editor').css({ border: 'solid 1px var(--error-border)' }))
		dialog.show()
		dialog.get_close_btn()
	})
}

time_and_expense.set_change_reason = async function (frm) {
	let values = await time_and_expense.change_reason_dialog(frm)
	cur_dialog.hide()
	frappe
		.xcall('time_and_expense.workflows.provide_change_reason', {
			doctype: frm.doc.doctype,
			docname: frm.doc.name,
			change_reason: values.change_reason,
			user: frappe.session.user,
		})
		.then(r => {
			frm.save()
		})
}

// required to add onload triggers to report view
frappe.views.ReportView.prototype.setup_new_doc_event = function () {
	this.$no_result.find('.btn-new-doc').click(() => {
		if (this.settings.primary_action) {
			this.settings.primary_action()
		} else {
			this.make_new_doc()
		}
	})
}

// add onload trigger to report view
frappe.views.ReportView.prototype.setup_view = function () {
	if (this.report_settings && this.settings.onload) {
		this.settings.onload(this)
	}
	this.setup_columns()
	this.setup_new_doc_event() // patched from above
	this.page.main.addClass('report-view')
	this.page.body[0].style.setProperty('--report-filter-height', this.page.page_form.css('height'))
	this.page.body.parent().css('margin-bottom', 'unset')
}

// add 'on report render' event
frappe.views.QueryReport.prototype.hide_loading_screen = function () {
	this.$loading.hide()
	if (this.report_settings && this.report_settings.on_report_render && this.data && this.data.length > 0) {
		this.report_settings.on_report_render(this)
	}
}

time_and_expense.shortened_docname = docname => {
	return docname.split('-').slice(3).join('-')
}

time_and_expense.set_date_filters = function () {
	const start_date = frappe.query_report.get_filter_value('start_date')
	if (start_date) {
		const d = moment(start_date)
		if (d.date() == 1) {
			frappe.query_report.set_filter_value('end_date', d.endOf('month').format('YYYY-MM-DD'))
		} else {
			frappe.query_report.set_filter_value('end_date', start_date)
		}
	}
}

frappe.form.link_formatters['Task'] = (value, doc) => {
	if (doc.task && doc.task_subject !== value) {
		return value + ': ' + doc.task_subject
	} else {
		return value
	}
}
